<template>
  <div>
    <div class="searchBox">
      <div class="searchLeft">
        <!-- <div>
          <el-input
              size="medium"
              clearable
              v-model="searchForm.name"
              placeholder="请输入nft名称"
              @keyup.enter.native="getTableList"
          ></el-input>
        </div>
        <el-button size="medium" type="primary" icon="el-icon-search" @click="getTableList">搜索</el-button> -->
      </div>
      <div class="searchRight">
        <el-button
          size="medium"
          type="primary"
          icon="el-icon-plus"
          @click="addTypeFunc"
          >新增类别</el-button
        >
      </div>
    </div>
    <el-table border :data="tableData" height="76vh" style="width: 99%">
      <el-table-column prop="id" label="id"></el-table-column>
      <el-table-column prop="category" label="类型"></el-table-column>
      <el-table-column prop="taskName" label="任务"></el-table-column>
      <el-table-column prop="needBuyId" label="购买ID"></el-table-column>
      <el-table-column prop="needBuyNum" label="购买数量"></el-table-column>
      <el-table-column prop="allValidNum" label="校验总次数"></el-table-column>
      <el-table-column prop="speed" label="speed">
        <template slot-scope="scope"> {{ scope.row.speed }} /小时</template>
      </el-table-column>
      <el-table-column prop="energy" label="energy">
        <template slot-scope="scope"> {{ scope.row.energy }} /秒</template>
      </el-table-column>
      <el-table-column prop="lifespan" label="lifespan">
        <template slot-scope="scope"> {{ scope.row.lifespan }} /秒</template>
      </el-table-column>
      <el-table-column label="操作" width="240">
        <template slot-scope="scope">
          <el-button
            size="small"
            type="warning"
            @click="editTypeFunc(scope.row)"
            >编辑</el-button
          >
          <el-button size="small" type="primary" @click="toDetail(scope.row)"
            >NFT商品</el-button
          >
          <el-button
            size="small"
            type="danger" 
            @click="delFunc(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog  :close-on-click-modal="false" :title="ruleForm.id?'编辑nft':'新增nft'" :visible.sync="addVisible" width="700px" top="3vh">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
        class="ruleFormNft"
      >
        <div class="priceBox" v-for="(item,index) in ruleForm.buyPriceList" :key="index">
          <p class="del" @click="delPriceFunc(index)" v-if="ruleForm.buyPriceList.length>1"><i class="el-icon-delete"></i> 删除</p>
          <el-form-item label="代币链id" :prop="'buyPriceList.' + index + '.coinId'" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model="item.coinId"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否是平台币" :prop="'buyPriceList.' + index + '.platformCoin'" :rules="rules.changeRule">
          <el-radio-group v-model="item.platformCoin">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="购买币种合约地址" :prop="'buyPriceList.' + index + '.costCurrencyAddress'" :rules="rules.blurRule">
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="item.costCurrencyAddress"
          ></el-input>
        </el-form-item>
        <el-form-item label="购买币种" :prop="'buyPriceList.' + index + '.costCurrency'" :rules="rules.changeRule">
          <el-select
            size="medium"
            clearable
            v-model="item.costCurrency"
            placeholder="请选择"
          >
            <el-option
              v-for="item in currencyOptions"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="购买价格" :prop="'buyPriceList.' + index + '.price'" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model="item.price"
          ></el-input>
        </el-form-item>
        <el-form-item label="充值链接" v-if="item.costCurrency!='talk'">
          <el-input
            placeholder="请输入内容"
            readonly
            type="textarea"
            v-model="item.url"
          ></el-input>
        </el-form-item>
        </div>
        <el-form-item label="">
          <el-button
            size="medium"
            type="warning"
            @click="addPriceFunc"
            >添加价格</el-button
          >
          
        </el-form-item>
        <div v-if="!ruleForm.id">
          <el-form-item label="NFT名称" prop="name" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="ruleForm.name"
            ></el-input>
          </el-form-item>
          <el-form-item label="属性 - 族群" prop="ethnic" :rules="rules.changeRule">
            <el-select
              size="medium"
              v-model="ruleForm.ethnic"
              placeholder="类型"
            >
              <el-option
                v-for="(item,index) in ethnicOptions"
                :key="index"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="属性 - 角色" prop="role" :rules="rules.changeRule">
            <el-select
              size="medium"
              v-model="ruleForm.role"
              placeholder="类型"
            >
              <el-option
                v-for="(item,index) in roleOptions"
                :key="index"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="文件Id" prop="tokenId" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="ruleForm.tokenId"
            ></el-input>
          </el-form-item>
          <el-form-item label="图片" prop="img" :rules="rules.typeIcon">
            <uploadImg 
              :disabled="!ruleForm.ethnic||!ruleForm.role||!ruleForm.tokenId"  
              @change="uploadImgChange" 
              :imageFile="[{url:ruleForm.img}]" 
              :reqType="reqType"
              uploadApiUrl="/nft/upload/nft-image">
            </uploadImg>
            <p style="color:#E6A23C">请先选择种族、角色和文件ID</p>
          </el-form-item>
          <el-form-item label="操作" prop="name" :rules="rules.blurRule">
            <el-button size="medium" type="primary" icon="el-icon-search" @click="handleJson">生成json</el-button>
          </el-form-item>
          <el-form-item label="json">
              <el-input
                type="textarea"
                :rows="5"
                disabled
                v-model="ruleForm.json">
              </el-input>
          </el-form-item>
          <el-form-item label="json地址">
            <el-input
              disabled
              v-model="ruleForm.tokenUri"
            ></el-input>
          </el-form-item>
          <el-form-item label="图片地址">
            <el-input
              disabled
              v-model="ruleForm.img"
            ></el-input>
          </el-form-item>
        </div>
 
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFunc">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog  :close-on-click-modal="false" title="新增类别" :visible.sync="addTypeVisible" width="700px">
      <el-form
        :model="ruleTypeForm"
        :rules="rules"
        ref="ruleTypeForm"
        label-width="140px"
        class="demo-ruleForm"
      >

        <el-form-item label="类别" prop="category" :rules="rules.changeRule">
          <el-select
            size="medium"
            clearable
            v-model="ruleTypeForm.category"
            placeholder="类别"
          >
            <el-option
              v-for="item in categoryOptions"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务">
          <el-select
            size="medium"
            clearable
            v-model="ruleTypeForm.taskId"
            placeholder="类型"
          >
            <el-option
              v-for="(item,index) in taskOptions"
              :key="index"
              :label="item.taskName"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="购买ID">
          <el-input
            placeholder="请输入内容"
            v-model="ruleTypeForm.needBuyId"
          ></el-input>
        </el-form-item>
        <el-form-item label="购买数量">
          <el-input
            placeholder="请输入内容"
            v-model.number="ruleTypeForm.needBuyNum"
          ></el-input>
        </el-form-item>
        <el-form-item label="校验总次数" prop="allValidNum" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model.number="ruleTypeForm.allValidNum"
          ></el-input>
        </el-form-item>
        <el-form-item label="购买上限" prop="upBuyLimit" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model.number="ruleTypeForm.upBuyLimit"
          ></el-input>
        </el-form-item>
        <el-form-item label="speed" prop="speed" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model.number="ruleTypeForm.speed"
          >
              <template slot="append">/ 小时</template>
          </el-input>
        </el-form-item>
        <el-form-item label="energy" prop="energy" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model.number="ruleTypeForm.energy"
          >
              <template slot="append">/ 秒</template>
          </el-input>
        </el-form-item>
        <el-form-item label="lifespan" prop="lifespan" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model.number="ruleTypeForm.lifespan"
          >
              <template slot="append">/ 秒</template>
          </el-input> 
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addTypeVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitTypeFunc">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog  :close-on-click-modal="false" title="NFT商品列表" :visible.sync="detailVisible" width="940px" top="3vh">
      <div class="searchBox">
        <div class="searchLeft">
        </div>
        <div class="searchRight">
          <el-button
            size="medium"
            type="warning"
            icon="el-icon-plus"
            @click="addFunc"
            >新增</el-button
          >
        </div>
      </div>
      <el-table border :data="nftBuyInfoList" height="65vh" style="width: 99%">
        <el-table-column label="序号" width="80">
          <template slot-scope="scope"> {{ scope.$index + 1 }} </template>
        </el-table-column>
        <el-table-column prop="id" label="id" width="80"></el-table-column>
        <el-table-column prop="name" label="nft名称" width="80"></el-table-column>
        <el-table-column prop="tokenUri" label="json地址"></el-table-column>
        <el-table-column label="图片" width="140">
            <template slot-scope="scope">
              <img :width="100" :src="scope.row.img" class="icon" />
            </template>
        </el-table-column>
        <el-table-column prop="allValidNum" label="price" width="140">
          <template slot-scope="scope"> 
            <div v-for="(item,index) in scope.row.buyPriceList" :key="index">
              <p>{{item.price +' '+ item.costCurrency}}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
              <el-button
                size="small"
                type="primary" 
                @click="editNftFunc(scope.row)"
                >编辑</el-button
              >
              <el-button
                size="small"
                type="danger" 
                @click="delNftFunc(scope.row)"
                >删除</el-button
              >
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="detailVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import uploadImg from '@/components/uploadImg'
import pagination from '@/components/pagination'
export default {
  data() {
    return {
      chargeUrl:process.env.VUE_APP_CHARGE_URL,
      addVisible: false,
      addTypeVisible:false,
      detailVisible:false,
      nftBuyInfoList:[],
      ruleTypeForm:{},
      ruleForm: {
        buyPriceList: []
      },
      searchForm: {
        pageNo: 1,
        pageSize: 10
      },
      categoryOptions: {
        v1: 'v1',
        v2: 'v2',
        v3: 'v3',
        v4: 'v4',
        v5: 'v5'
      },
      taskOptions:[],
      currencyOptions:{
        eth: 'eth',
        usdt: 'usdt',
        talk: 'talk'
      },
      ethnicOptions: [// 种族
        // { value: '1', label: '1.绿林' },
        // { value: '2', label: '2.帝国' },
        // { value: '3', label: '3.虚空' },
        // { value: '4', label: '4.暗星' },
        // { value: '5', label: '5.岩穴' }
        { value: '6', label: '6.VIP' }
      ],
      roleOptions: [
        // { value: '1', label: '1.蚁后（族群领袖）' },
        // { value: '2', label: '2.明星蚁（蚂蚁KOL）' },
        // { value: '3', label: '3.幸运蚁（幸运宠儿）' },
        // { value: '4', label: '4.先知蚁（智慧代表）' },
        { value: '5', label: '5.工蚁（价值创造者）' }
      ],
      tableData: [],
      dialogVisible: false,
      rules: {
        blurRule: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        changeRule: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        typeIcon: [{ required: true, message: "请上传图片", trigger: "change" }],
      },
      row:{}
    };
  },
  components:{uploadImg,pagination},
  computed:{
    reqType(){
      const {ethnic, role, tokenId} = this.ruleForm
      let reqType = {
        path:`${ethnic}/${role}`,
        id:tokenId,
        type:'IMAGE'
      }
      return reqType
    }
  },
  watch:{
    ruleForm:{
      handler(val){ 
        this.ruleForm.buyPriceList.forEach(item=>{
          if(item.costCurrency&&item.price){
            item.url = `${process.env.VUE_APP_CHARGE_URL}?cryptoAmount=${item.price}&cryptoCurrency=${item.costCurrency==='usdt'?'USDC':item.costCurrency}`
          }
        })
      },
      deep:true
    }
  },
  created() {
    this.getTableList();
    this.getTaskList();
  },
  methods: {
    editNftFunc(row){
      this.ruleForm = {...row}
      if(!this.ruleForm.buyPriceList){
        this.ruleForm.buyPriceList = [{}]
      } 
      this.addVisible = true
    },
    toDetail(row){
      this.row = row
      console.log(this.row)
      this.nftBuyInfoList = row.nftBuyInfoList
      this.detailVisible = true
    },
    addFunc(){
      this.ruleForm = {buyPriceList:[]};
      this.ruleForm.ethnic = '6'
      this.ruleForm.role = '5'
      this.ruleForm.buyPriceList.push({})
      this.addVisible = true;
    },
    addPriceFunc(){
      this.ruleForm.buyPriceList.push({})
    },
    delPriceFunc(index){
      this.ruleForm.buyPriceList.splice(index,1)
    },
    addTypeFunc(){
      this.ruleTypeForm = {};
      this.addTypeVisible = true;
    },
    editTypeFunc(row){
      this.ruleTypeForm = row
      this.addTypeVisible = true
    },
    uploadImgChange(val){
      if(val&&val.length>0) {
        this.ruleForm.img=val[0].url
        this.$forceUpdate();
      }
    },
    async getTaskList(){
      let res = await this.$http.taskList({pageNo:1,pageSize:100});
      if (res) {
        this.taskOptions = res.result.data;
      }
    },
    async getTableList(val) {
      if(val) {
        this.searchForm.pageNo = 1
      }
      let res = await this.$http.nftListConfig({...this.searchForm});
      if (res) {
        this.tableData = res.data;
      }
    },
    async submitFunc() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if(!this.ruleForm.tokenUri){
            this.$message.error('请先生成json');
            return
          }
          this.ruleForm.nftConfigId = this.row.id
          let res = await this.$http.nftSaveInfo({...this.ruleForm});
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
            this.getDetailList();
            this.addVisible = false;
            this.$refs.ruleForm.resetFields();
          }
        }
      });
    },
    async handleJson() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const {ethnic, role, tokenId} = this.ruleForm
          const ipfsJson = {}
          ipfsJson.attributes = []
          ipfsJson.attributes.push({ 'trait_type': 'role', 'value': this.ruleForm.role })
          ipfsJson.attributes.push({ 'trait_type': 'ethnic', 'value': this.ruleForm.ethnic })
          ipfsJson.image = this.ruleForm.img
          ipfsJson.extend_info = {}
          ipfsJson.animation_url = ''
          ipfsJson.background_color = ''
          ipfsJson.name = this.ruleForm.name
          const jsonString = JSON.stringify(ipfsJson, null, 2)// 2表示缩进两个空格
          this.ruleForm.json = jsonString
          let form = {}
          form.json = jsonString
          form.id = this.ruleForm.tokenId
          form.path=`json/${ethnic}/${role}`
          let res = await this.$http.ipfsUploadJson({...form});
          if (res) {
            this.ruleForm.tokenUri = res.data.url;
            this.$forceUpdate();
          }
        }
      });
    },
    async submitTypeFunc() {
      this.$refs.ruleTypeForm.validate(async (valid) => {
        if (valid) {
          let res = await this.$http.nftSaveConfig({...this.ruleTypeForm});
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
            this.addTypeVisible = false;
            this.$refs.ruleTypeForm.resetFields();
          }
        }
      });
    },
    delFunc(row) {
      this.$confirm(`确定要删除么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.nftDelConfig({id:row.id})
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
          }
        })
        .catch(() => {});
    },
    async getDetailList() {
      let res = await this.$http.nftListConfig({pageNo:1,pageSize:100,id:this.row.id});
      if (res) {
        this.nftBuyInfoList = res.data[0].nftBuyInfoList;
        console.log(this.nftBuyInfoList,'this.nftBuyInfoList----------------------------------')
      }
    },
    delNftFunc(row){
      this.$confirm(`确定要删除么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.nftDelInfo({id:row.id})
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
            this.getDetailList();
          }
        })
        .catch(() => {});
    }
  },
};
</script>

<style lang="scss" scoped>
.ruleFormNft{
  height: 73vh;
  overflow-y: auto;
}
.priceBox{
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #f0f2f5;
  padding: 10px 0;
  margin-bottom: 10px;
  position: relative;
  .del{
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    color: #f56c6c;
    z-index: 10;
  }
}
</style>
